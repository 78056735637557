<template>
  <div class="p-grid p-fluid">
    <TopBar :breadcrumbItems="data.breadcrumbItems" />

    <div class="card">
      <Steps :model="data.stepItems" :readonly="true" />
    </div>

    <router-view :items="service" v-if="service"></router-view>

    <StepPagination
      :currentStep="data.currentStep"
      :stepItems="data.stepItems"
      :completeButtonLoading="data.isLoading"
      :requiredStepByStep="requiredStepByStep"
      :items="service"
      v-if="service"
      @updateStep="data.currentStep = $event"
      @complete="$emit('complete', service)"
    />
  </div>
</template>

<script>
import StepPagination from '@/components/Utilities/StepPagination.vue';
import TopBar from '@/components/Utilities/TopBarBreadCrumb.vue';
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/plugins/axios';
import ServiceModel from '@/models/service';

export default {
  components: {
    StepPagination,
    TopBar
  },
  props: {
    data: Object
  },
  setup(props) {
    const route = useRoute();

    let service = ref(null);

    const serviceTemplate = new ServiceModel({});

    const requiredStepByStep = reactive([
      [
        'name',
        'price',
        'type',
        'employee_id'
      ]
    ]);

    const serviceId = route.query.service;

    const scheduleData = ref([]);

    if (serviceId) {
      loadService();
    } else {
      service.value = serviceTemplate;
    }

    async function loadService() {
      try {
        let item = serviceTemplate;
        await axios.get(`service/${serviceId}`).then((res) => {
          const data = res.data.data;

          for (let key in data) {
            item[key] = data[key];
          }
          item.time_similar_agency =
            data.time_similar_agency === 'on' ||
            data.time_similar_agency === true
              ? true
              : false;

          if (data.schedule) {
            for (const sch of data.schedule) {
              const serviceSections = sch.sections.map((item) => ({
                openTime: item.startTime,
                closeTime: item.endTime,
                workPeriods: [
                  {
                    startTime: item.startTime,
                    endTime: item.endTime
                  }
                ]
              }));

              const scheduleInfo = {
                label: sch.label,
                active: sch.active
              };

              scheduleData.value.push({
                scheduleInfo,
                sections: serviceSections
              });

              //   service.schedule.push({
              //     scheduleInfo,
              //     workPeriods: serviceSections[0].workPeriods
              //   });
            }
          }

          service.value = item;
        });
      } catch (err) {
        console.log(err, 'errService');
      }
    }
    return {
      service,
      requiredStepByStep
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}
.service-info {
  margin: 0 auto;
  width: 100%;
}
</style>
