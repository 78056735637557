import {UserDataInfo} from '@/plugins/api'
import { getBaseSchedule } from '@/store/data/schedule';

const userData = new UserDataInfo();
export default class ServiceModel {
  constructor(item) {
    if (item) {
        this.agenda_id = item.agenda_id ? item.agenda_id : [],
        this.employee_id = item.employee_id ? item.employee_id : [],
        this.category_id = item.category_id ? item.category_id : [],
        this.motifs_ids = item.motifs_ids ? item.motifs_ids : [],
        this.organisation_id = userData.organisationId(),
        this.name = item.name ? item.name : null,
        this.description = item.description ? item.description : null,
        this.book_duration = item.book_duration ? item.book_duration : null,
        this.color = item.color ? item.color : null,
        this.price = item.price ? item.price : null,
        this.currency = item.currency ? item.currency : null,
        this.time_similar_agency = item.time_similar_agency ? item.time_similar_agency : null,
        this.is_fill_contact = item.is_fill_contact ? item.is_fill_contact : false,
        this.is_schedule_employee = item.is_schedule_employee ? item.is_schedule_employee : true,
        this.schedule = item.schedule ? item.schedule : getBaseSchedule(),
        this.is_question = item.is_question ? item.is_question : false,
        this.count_booking_per_user = 1,
        this.type = 'Human',
        this.questions = [
          {
            name: ''
          }
        ]
    }
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}
